<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-row class="justify-content-center">
        <b-col xl="6" lg="8" md="10">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="icon-settings mr-2"></i><strong> Paramètres de l'exploitation</strong>
            </div>
            <b-form>
              <p class="text-danger" v-if="hasError">{{errorMessage}}</p>

              <c-input type="image" v-model="picture" default="DEFAULT_EXPLOITATION_PICTURE"></c-input>

              <c-input container-class="mb-3" label="Nom de l'exploitation" placeholder="Ex: Mon champ"
                       v-model="name" :state="nameState">
                Veuillez saisir un nom
              </c-input>

              <c-input container-class="mb-3" type="select" label="Devise" v-model="currency">
                <template slot="first">
                  <option :value="null" disabled>-- Sélectionnez une devise --</option>
                  <option value="XAF" selected>XAF</option>
                  <option value="DOL">&dollar;</option>
                  <option value="EUR">&euro;</option>
                  <option value="POU">&pound;</option>
                  <option value="INR">&#x20b9;</option>
                </template>
                <template slot="infoMessage">
                  Cette dévise sera utilisée comme unité des prix dans cette exploitation agricole.
                </template>
              </c-input>

              <c-input container-class="mb-3" type="textarea" label="Commentaire"
                       placeholder="Entrez un commentaire..." v-model="comment">
              </c-input>

              <b-input-group class="mb-3">
                <b-button variant="link" class="px-0 text-danger" @click.prevent="deleteExploitation">
                  Supprimer cette exploitation
                </b-button>
              </b-input-group>
            </b-form>

            <div id="btns" class="text-center">
              <button-spinner variant="success" type="submit" class="px-4" @click="updateExploitation"
                              :fetching="fetchingUpdateExploitation">
                Enregistrer
              </button-spinner>
              <b-button letiant="secondary" @click="onCancel" class="ml-2">
                Annuler
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Regex, Toast, env, asset} from "../../helpers";


  export default {
    name: "ExploitationSettings",
    title: "PIA - Paramètres de l'exploitation",
    data() {
      return {
        picture: null,
        name: '',
        currency: null,
        comment: '',
        submitted: false,
        error: null,
        fetchingUpdateExploitation: false,
        fetchingDeleteExploitation: false
      }
    },
    created() {
      this.picture = asset(this.exploitation.picture)
      this.name = this.exploitation.name
      this.currency = this.exploitation.currency || null
      this.comment = this.exploitation.comment
    },
    computed: {
      exploitation() {
        return this.$store.state.exploitation
      },
      _picture() {
        return this.picture ? this.picture : env('DEFAULT_EXPLOITATION_PICTURE')
      },
      nameState() {
        return !this.submitted || this.name.trim().length >= 3 ? null : false
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    methods: {
      asset,
      onPictureChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        const reader = new FileReader();
        const vm = this;

        reader.onload = (e) => {
          vm.picture = e.target.result;
        };
        reader.readAsDataURL(files[0]);
      },
      redirect() {
        this.$router.push({name: 'Home'})
      },
      valid() {
        return this.name.trim().length >= 3
      },
      updateExploitation() {
        this.submitted = true;
        if (!this.valid()) return

        this.fetchingUpdateExploitation = true
        Api.post('/exploitation/update', {
          exploitationId: this.exploitation.id,
          picture: Regex.isNullOrURL(this.picture) ? null : this.picture,
          name: this.name,
          currency: this.currency,
          comment: this.comment
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              Toast.success('Exploitation mise à jour avec succès !')
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingUpdateExploitation = false
          })
      },
      deleteExploitation() {
        const res = confirm(`Etes-vous sûr de vouloir supprimer cette exploitation ?`)
        if (res) {
          this.fetchingDeleteExploitation = true
          Api.post('/exploitation/delete', {
            exploitationId: this.exploitation.id
          })
            .then(res => {
              if (res.data.status === 'success' && res.data.data) {
                Toast.success('Exploitation supprimée avec succès !')
                this.redirect()
              }
              else {
                this.error = res.data.error
              }
            })
            .catch(error => {
              this.error = {
                message: 'Echec de la connexion au serveur'
              }
            })
            .then(() => {
              this.fetchingDeleteExploitation = false
            })
        }
      },
      onCancel() {
        this.$router.push({name: 'Exploitation', params: {id: this.exploitation.id}})
      }
    }
  }
</script>

<style scoped lang="stylus">
  #unit-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  #exploitation-img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
</style>
